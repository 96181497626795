import axios from "axios";
import { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import AppContext from "../context/AppContext";
import { notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";


export default function ProductoInsumo(props) {

    const { active, api } = useContext(AppContext);
    const [productos, setProductos] = useState([]);
    const [usuario] = useCookies(['usuarioadmi']);
    const [consumo, setConsumo] = useState('');
    const [subtipo, setSubtipo] = useState([]);
    const [nombreProducto, setNombreProducto] = useState('');
    const [selectedSubtipo, setSelectedSubtipo] = useState('');

    const [validate, setValidate] = useState(false);

    const fetchSubtipo = async () => {
        
        try {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ idusuario: usuario.usuarioadmi.id })
            };

            const response = await fetch(`${api}/api/productos/`, requestOptions);
            const data = await response.json();
            setSubtipo(data);



        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {


        fetchSubtipo();

    }, []);


    const handleAlta = async (event) => {

        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        setValidate(true)

        setValidate(false)


        handleAgregarProducto()

    };

    // Maneja la adición de nuevos productos a la lista
    const handleAgregarProducto = () => {


        const nuevoProducto = {
            nombre: selectedSubtipo,
            consumo: consumo,
        };
debugger
        setProductos([...productos, nuevoProducto]);
        // Limpia los campos del formulario
        setNombreProducto('');
        setConsumo('');


    };


  const eliminarProducto = (id) => {
    setProductos(productos.filter((producto) => producto.id !== id));
  };

    // Función para agrupar productos por subtipo
    const agruparPorSubtipo = (productos) => {
        return productos;
            
    };


    const productosAgrupados = agruparPorSubtipo(productos);

    const [newCategoryName, setNewCategoryName] = useState('');

    const handleCategoryChange = (e) => {
        debugger
        const value = e.target.value;
        setSelectedSubtipo(value);

        // Si se selecciona "nuevo", se limpia el nombre de la nueva categoría
        if (value === "nuevo") {
            setNewCategoryName('');
        }
    };

    const handleSubmitNewCategory = async () => {
        if (newCategoryName.trim() === '') {
            alert("Por favor, ingresa un nombre para la nueva categoría.");
            return;
        }

        try {
            const response = await fetch(`${api}/api/ingrediente/subtipo/alta`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ nombre: newCategoryName, idusuario: usuario.usuarioadmi.id }),
            });

            if (!response.ok) {
                throw new Error('Error al agregar la nueva categoría');
            }

            const result = await response.status;
            notification.open({
                message: 'Notificación',
                description: 'Registro exitoso',
                icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
            });
            setSelectedSubtipo("")
            fetchSubtipo();
        } catch (error) {
            console.error('Error:', error);
        }
    };

    return (
        <div className="container mt-2 border rounded p-2 bg-white   ">
            <div class="accordion " id="accordionPanelsStayOpenExample border  rounded list-group-item ">
                <div class="accordion-item bg-white-custom ">
                    <h2 class="accordion-header bg-white-custom ">
                        <button
                            class="accordion-button bg-light flex  text-dark"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#panelsStayOpen-collapseOne"
                            aria-expanded="true"
                            aria-controls="panelsStayOpen-collapseOne"
                        >
                            <strong className="pr-1">Consumo Unidad Producto  </strong>
                        </button>
                    </h2>
                    <div
                        id="panelsStayOpen-collapseOne"
                        class="accordion-collapse collapse show bg-white text-dark"
                    >
                        <div class="accordion-body ">



                            <div className="row mb-3">
                                <label className="col-sm-2 col-form-label">Producto:</label>
                                <div className="col-sm-6">
                                    <select
                                        className="form-select"
                                        value={selectedSubtipo}
                                        onChange={handleCategoryChange}
                                        required={validate}
                                    >
                                        <option value="">Elegir un Producto</option>
                                        {Array.isArray(subtipo) && subtipo.map((sub) => (
                                            <option key={sub.id} value={sub.nombre}>{sub.nombre +': ' + sub.ingredientes}</option>
                                        ))}

                                    </select>


                                </div>
                            </div>

                            {/*  <div className="row mb-3">
                                <label className="col-sm-2 col-form-label">Nombre:</label>
                                <input
                                    className="form-control col-sm-5"
                                    type="text"
                                    value={nombreProducto}
                                    onChange={(e) => setNombreProducto(e.target.value)}
                                    required={validate}
                                />
                            </div> */}
                            <div className="row mb-3">
                                <label for="inputText" className="col-sm-5  col-form-label pr-0 w-80">Cantidad de Insumo Consumida:</label>
                                <div className="col-sm-3 input-group pl-0 ">
                                    <input type="number" className="form-control" name="consumo" value={consumo} onChange={(e) => setConsumo(e.target.value)} required />
                                    <span class="input-group-text">{props?.unidadSeleccionada}</span>
                                </div>
                            </div>
                            <div className="center">
                                <button className=" btn btn-primaryMenu" onClick={(e) => handleAlta(e)}>
                                    Asociar Producto
                                </button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>



            <div className="container mt-2 row mb-2 text-center">
                <h5 className="card-title">Lista de Productos Asociados:</h5>

                <ul>
                    {productos.map((producto) => (
                        <li key={producto.id} style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
                            <span style={{ flex: 1 }}>
                                Producto:<strong> {producto.nombre} </strong>
                            | Consumo Unitario:   <strong> {producto.consumo + " "+ props?.unidadSeleccionada }</strong> 
                            </span>
                            <button onClick={() => eliminarProducto(producto.id)} style={{ marginLeft: "10px" }}>
                                Eliminar
                            </button>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
}