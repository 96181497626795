import React from "react";
import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function CartSucess() {
  const [usuario] = useCookies(["usuario"]);
  const currentUrl = window.location.href;
  let approved = currentUrl.includes("approved");

  return (
    <section id="contact" className="sectionMenu section-bg">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Pedido y Pagos</h2>
          <p>Pago Online</p>
        </div>
      </div>

      <div className="container" data-aos="fade-up">
        <div class="wizard-progress mb-4">
          <div class="step complete">
            Pedir<div class="node"></div>
          </div>
          <div class="step complete">
            Pagar<div class="node"></div>
          </div>
          <div class="step  in-progress">
            Disfrutar<div class="node"></div>
          </div>
        </div>
    { approved &&   <div className="alert alert-warning" role="alert">
          <h6>
            ¡Tu pedido ya fue enviado! <br></br>{" "}
          </h6>
        </div>}
        <br></br>

        <div id="cart-add" className="section-p1 center">
          {approved ? (
            <div id="subtotal" className="align-right">
              <h4 className="center">
                <i className="bi bi-check-circle-fill"></i>¡Gracias por tu
                pedido!
              </h4>
              <Link to={`/${usuario.usuario.local}/menu`} className="center">
                <button className="normal second topbot">Volver a Menú</button>
              </Link>{" "}
            </div>
          ) : (
            <div id="subtotal" className="align-right">
              <h4 className="center">
                <i className="bi bi-exclamation-circle yellowColor"></i>Sin
                novedad del pago
              </h4>
              <h6>
                {" "}
                Por favor, contactar con el local para informar la forma de pago
              </h6>
              <Link to={`${usuario.usuario.id}/menu`} className="center">
                <button className="normal second topbot">Ver Menu</button>
              </Link>{" "}
            </div>
          )}

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog">
              <div className="modal-content modal-contentMenu">
                <div className="modal-header">
                  <span
                    data-bs-dismiss="modal"
                    className="btn-close material-icons-outlined"
                    translate="no"
                  >
                    close
                  </span>
                </div>
                <div className="modal-body modal-bodyMenu">
                  <form>
                    <div className="accordion" id="accordionExample">
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseOne"
                            aria-expanded="true"
                            aria-controls="collapseOne"
                          >
                            Pago Efectivo
                          </button>
                        </h2>
                        <div
                          id="collapseOne"
                          className="accordion-collapse collapse show"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Al retirar el pedido podrás pagar con efectivo.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseTwo"
                            aria-expanded="false"
                            aria-controls="collapseTwo"
                          >
                            Pago Tarjeta Debito-Crédito
                          </button>
                        </h2>
                        <div
                          id="collapseTwo"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Al retirar el pedido podrás pagar con tu tarjeta de
                            debito o crédito.
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item">
                        <h2 className="accordion-header">
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target="#collapseThree"
                            aria-expanded="false"
                            aria-controls="collapseThree"
                          >
                            Transferencias - Envio Dinero MP
                          </button>
                        </h2>
                        <div
                          id="collapseThree"
                          className="accordion-collapse collapse"
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            Sólo se acepta transferencia directa desde el mismo
                            banco emisor al banco receptor.
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-contentMenu">
              <div className="modal-header">
              <span data-bs-dismiss="modal" className="btn-close material-icons-outlined" translate="no">close</span>

            </div>
          
               
              </div></div>

          </div> */}

          {/* <div id="coupon">
            <h3>Aplicar Cupón</h3>
            <div className="internal">
              <input type="text" placeholder="Ingresa Tu Cupón" />
              <button className="normal">Agregar</button>
            </div>
          </div> */}
        </div>
      </div>
    </section>
  );
}
