
import axios from "axios";
import React, { useContext, useState } from "react";
import { useCookies } from "react-cookie";
import AppContext from "../../context/AppContext";


export default function MesaAlta() {

    const { active, api } = useContext(AppContext);
    const [registroExitoso, setRegistroExitoso] = useState(false);
    const [usuario] = useCookies(['usuarioadmi']);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);
    const user = {
      mesa: formData.get('mesa'),
      piso: formData.get('piso'),
      capacidad: formData.get('capacidad'),
      observacion: formData.get('observacion'),
      idusuario: usuario.usuarioadmi.id,
    };
    const requestOptions = {
      method: 'POST',
      body: JSON.stringify(user),
      headers: {
        'Content-Type': 'application/json'
      }
    };
    const response = await fetch(`${api}/api/mesas/alta`, requestOptions);
    const data = await response.status;
    console.log(data);

    // Si la respuesta indica que el registro fue exitoso,
    // actualizamos la variable de estado para mostrar el mensaje.
    if (data === 200) {
      setRegistroExitoso(true);
      event.target.reset();
    }
  };
    return(
<main id="main" className={active === 'active' ? 'main active' : 'main'}>

{/* <div className="pagetitle">
  <nav>
    <ol className="breadcrumb">
      <h2>Gestión Mesa</h2>
    </ol>
  </nav>
</div> */}

{registroExitoso && (
        <div className="alert alert-success" role="alert">
          ¡Registro exitoso!
        </div>
      )}
<section className="section">
  <div className="row">
    <div className="col-lg-8">

      <div className="card">
        <div className="card-body">
          <h5 className="card-title">Alta Mesa</h5>


          <form onSubmit={handleSubmit}>
            <div className="row mb-3">
              <label for="inputText" className="col-sm-2 col-form-label">Mesa</label>
              <div className="col-sm-5">
                <input type="text" className="form-control" name="mesa"/>
              </div>
            </div>
            <div className="row mb-3">
              <label for="inputEmail" className="col-sm-2 col-form-label">Piso</label>
              <div className="col-sm-5">
                <input type="text" className="form-control" name="piso"/>
              </div>
            </div>
            <div className="row mb-3">
              <label for="inputPassword" className="col-sm-2 col-form-label">Capacidad</label>
              <div className="col-sm-5">
                <input type="number" className="form-control" name="capacidad"/>
              </div>
            </div>
            <div className="row mb-3">
              <label for="inputNumber" className="col-sm-2 col-form-label">Observación</label>
              <div className="col-sm-5">
                <input type="text" className="form-control" name="Observacion"/>
              </div>
            </div>
          
            {/* <div className="row mb-3">
              <label for="inputDate" className="col-sm-2 col-form-label">Plan</label>
              

              <div className="col-sm-5">
                <select className="form-select" aria-label="Default select example">
                  <option selected="">Open this select menu</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
            </div>*/}
            <div className="text-center"> 
            <button type="submit" className="btn btn-primary rounded-pill">
                                    Registrar
                                </button>
                                </div>

          </form>

        </div>
      </div>

    </div>

  </div>
</section>

</main>

    )

}