
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";
import Adicionales from "../../components/adicionales";
import { notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";


export default function ProductoEditar() {
  const navigate = useNavigate();
  const { id } = useParams();
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const { active, api } = useContext(AppContext);
  const [categorias, setCategorias] = useState([]);
  const [categoriaId, setCategoriaId] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [usuario] = useCookies(['usuarioadmi']);
  const [loadingenery, setLoadingenery] = useState(true);
  const [values, setValues] = useState({
    id: '',
    nombre: '',
    ingredientes: '',
    precio: '',
    stock: '',
    idcategoria: '',
    idusuario: '',
    img: selectedImage,
    activo: '',
    sintacc: '',


  });
  const [selectedItems, setSelectedItems] = useState([]);

  // Función que actualiza el estado en el padre
  const handleSelectedItemsUpdate = (newSelectedItems) => {
    setSelectedItems(newSelectedItems);
  };


  useEffect(() => {

    async function fetchProducto() {

      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id: id, idusuario: usuario.usuarioadmi.id })
        };

        const response = await fetch(`${api}/api/productos/producto`, requestOptions);
        const data = await response.json();
        setValues(data);
        setLoadingenery(false)

      } catch (error) {
        console.log(error)
      }
    }

    fetchProducto();


  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setLoadingenery(true)
    debugger
    const formData = new FormData();
    formData.append('imgs', selectedImage); // Agrega la imagen al formData

    formData.append('id', values.id);
    formData.append('nombre', values.nombre);
    formData.append('ingredientes', values.ingredientes);
    formData.append('precio', values.precio);
    formData.append('idcategoria', values.idcategoria.id ?  values.idcategoria.id :  values.idcategoria );
    formData.append('usuario', usuario.usuarioadmi.id);
    formData.append('stock', values.stock !== null && values.stock !== undefined ? values.stock : "");
    formData.append('activo', values.activo);
    formData.append('sintacc', values.sintacc);
    formData.append('ingrediente', selectedItems);

    const getData = async (event) => {


      try {
        const response = await axios.post(`${api}/api/productos/editar`, formData);

        if (response.status === 200 || response.status === 201) {
          setRegistroExitoso(true);

          setLoadingenery(false)

          notification.open({
            message: 'Notificación',
            description: 'Actualización exitosa',
            icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
          });
          navigate(-1);
        }
      } catch (error) {
        notification.open({
          message: "Ocurrio un error 😢",
          description: `${error.response?.data || error.message}`,
          icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
        });

        setLoadingenery(false)

        console.log(error)
      }
    }


    await getData(event);
    // event &&  event.target.reset();
  };

  useEffect(() => {
    async function fetchCategorias() {

      try {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ idusuario: usuario.usuarioadmi.id })
        };

        const response = await fetch(`${api}/api/categorias/`, requestOptions);
        const data = await response.json();
        setCategorias(data);
      } catch (error) {
        console.log(error)
      }



    }

    fetchCategorias();
  }, []);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === 'file') {
      setSelectedImage(event.target.files[0]);
      setValues((prevState) => ({ ...prevState, img: event.target.files[0] }));
    } else if (type === 'checkbox') {
      setValues((prevState) => ({ ...prevState, [name]: checked }));
    } else {
      setValues((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  return (
    <main id="main" className={active === 'active' ? 'main active' : 'main'}>

      <div className="pagetitle">
        <h1>Editar Producto</h1>
        {/* <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
            <li className="breadcrumb-item">Forms</li>
            <li className="breadcrumb-item active">Elements</li>
          </ol>
        </nav> */}
      </div>

      <section className="section">
        <div className="row">
          <div className="col-lg-6">

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Editar Producto</h5>

                {loadingenery ? (
                  <div className="center">  <div className=" spinner"></div></div>

                ) :
                  <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                      <label for="inputText" className="col-sm-2 col-form-label"> Activo</label>
                      <div className="col-sm-10 form-check form-switch d-flex justify-content-start">
                        <input className="form-check-input" type="checkbox" name="activo" id="gridCheck2"
                          checked={values.activo} onSubmit={handleSubmit}
                          onChange={handleChange} />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label for="inputText" className="col-sm-2 col-form-label">Nombre</label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" name="nombre" value={values.nombre}
                          onSubmit={handleSubmit} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label for="inputEmail" className="col-sm-2 col-form-label">Ingredientes</label>
                      <div className="col-sm-10">
                        <input type="text" className="form-control" name="ingredientes" value={values.ingredientes}
                          onSubmit={handleSubmit} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label for="inputPassword" className="col-sm-2 col-form-label">Precio</label>
                      <div className="col-sm-10">
                        <input type="number" className="form-control" name="precio" required
                          value={values.precio} onSubmit={handleSubmit} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label for="formFile" className="col-sm-2 col-form-label">Imagén</label>
                      <div className="col-sm-10">
                        <input className="form-control" type="file" id="formFile" name="img" accept="image/*"
                          onSubmit={handleSubmit} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label for="inputNumber" className="col-sm-2 col-form-label">Stock</label>
                      <div className="col-sm-10">
                        <input type="number" className="form-control" name="stock"
                          value={values.stock !== null && values.stock !== undefined ? values.stock : ""} onSubmit={handleSubmit} onChange={handleChange} />
                      </div>
                    </div>


                    <div className="row mb-3">
                      <label for="inputDate" className="col-sm-2 col-form-label">Categoría</label>
                      <div className="col-sm-10">
                        <select className="form-select" aria-label="Default select example" name="idcategoria" onSubmit={handleSubmit} onChange={handleChange} value={ values.idcategoria.id } required   >
                          {categorias.map((categoria) => (
                            <option key={categoria.id} value={categoria.id} >{categoria.nombre}</option>
                          ))}
                        </select>
                      </div>
                    </div>

                    <div className="row mb-3">
                      <label for="inputText" className="col-sm-2 col-form-label">Sin TACC</label>
                      <div className="col-sm-10 form-check form-switch d-flex justify-content-start">
                        <input className="form-check-input" type="checkbox" name="sintacc" id="gridCheck2"
                          checked={values.sintacc}
                          onChange={handleChange} />
                      </div>
                    </div>
                    <div className="row mb-3">
                      <label for="inputText" className="col-sm-2 col-form-label"> Extras / Requeridos</label>
                      <div className="col-sm-10 form-check form-switch d-flex justify-content-start">
                        <input className="form-check-input" type="checkbox" name="extra" id="gridCheck2"
                          checked={values.adicionales} onSubmit={handleSubmit}
                          onChange={handleChange} />
                      </div>
                    </div>


                    {values?.adicionales &&
                      <Adicionales idproducto={values.id} onSelectedItemsChange={handleSelectedItemsUpdate} ></Adicionales>
                    }
                    <div className="text-center pt-2">
                      <button type="submit" onClick={handleSubmit} className="btn btn-primary rounded-pill mr-1">
                        Actualizar
                      </button>

                      <Link to="/Admin/productos">
                        <button className="btn btn-primary rounded-pill">
                          Volver
                        </button></Link>
                    </div>

                  </form>}

              </div>
            </div>

          </div>

        </div>
      </section>

    </main>

  )

}
