
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import { notification } from "antd";


export default function CategoriaEditar(props) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const { active, api } = useContext(AppContext);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [usuario] = useCookies(['usuarioadmi']);
  const [values, setValues] = useState({
    id: '',
    nombre: '',
    orden: '',
    impresora: '',
    nombreimpresora:'',
    puerto:'',
    idusuario: '',

  });
  useEffect(() => {

    async function fetchProducto() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: id })
      };

      const response = await fetch(`${api}/api/categorias/id`, requestOptions);
      const data = await response.json();
      setValues(data);
      setLoading(false)

    }

    fetchProducto();
    console.log(values.idcategoria)


  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true)
    const formData = new FormData();

    formData.append('id', values.id);
    formData.append('nombre', values.nombre);
    formData.append('orden', values.orden);
    formData.append('impresora', values.impresora.toString());
    formData.append('nombreimpresora', values.nombreimpresora);
    formData.append('puerto', values.puerto);
    formData.append('idusuario', usuario.usuarioadmi.id);

    const getData = async () => {


      try {
        const response = await axios.put(`${api}/api/categorias/editar`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data', // Asegúrate de usar el encabezado adecuado
          },
        });
        console.log(response)
        if (response.status === 200 || response.status === 201) {
          setRegistroExitoso(true);
          notification.open({
            message: 'Notificación',
            description: 'Actualización exitosa',
            icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
          });
          navigate(-1);
          setIsLoading(false)
        }
      } catch (error) {
        setIsLoading(false)
        notification.open({
          message: "Ocurrio un error 😢",
          description: `${error.response?.data || error.message}`,
          icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
        });
      }
    }


    await getData();
  };

  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (type === 'file') {
      setSelectedImage(event.target.files[0]);
      setValues((prevState) => ({ ...prevState, img: event.target.files[0] }));
    } else {
      setValues((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const formatDate = (dateValue) => {
    if (!dateValue) return ''; // Manejar el caso donde dateValue no está definido

    const date = new Date(dateValue);

    if (isNaN(date.getTime())) {
      // Si no es un valor de fecha válido, maneja el error o devuelve un valor por defecto
      return '';
    }

    return date.toISOString().split('T')[0];
  };

  return (
    <main id="main" className={active === 'active' ? 'main active' : 'main'}>

      <div className="pagetitle">
        <h1>Editar Categoria y Impresoras</h1>
        {/* <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
            <li className="breadcrumb-item">Forms</li>
            <li className="breadcrumb-item active">Elements</li>
          </ol>
        </nav> */}
      </div>
      {registroExitoso && (
        <div className="alert alert-success" role="alert">
          ¡Actualización exitosa!
        </div>
      )}
      <section className="section">
        <div className="row">
          <div className="col-lg-6">

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Editar Categoria y Impresora</h5>
                {loading ? (
                  <div className="center">  <div className=" spinner"></div></div>

                ) :

                  <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                      <label for="inputText" className="col-sm-2 col-form-label">Nombre</label>
                      <div className="col-sm-4">
                        <input type="text" className="form-control" name="nombre" value={values.nombre}
                          onSubmit={handleSubmit} onChange={handleChange} />
                      </div>
                    </div>


                    <div className="row mb-3">
                      <label for="inputEmail" className="col-sm-2 col-form-label">Orden</label>
                      <div className="col-sm-4">
                        <input type="number" className="form-control" name="orden" value={values.orden}
                          onSubmit={handleSubmit} onChange={handleChange} />
                      </div>
                    </div>
                    <div className="row mb-3 ">
                      <label for="inputPassword" className="col-sm-2 col-form-label">Impresora</label>
                      <div className="col-sm-4 grid">
                        <div className=" input-group">
                          <span class="input-group-text">Nombre</span>
                          <input type="text" className="form-control" name="nombreimpresora"
                            value={values.nombreimpresora} onSubmit={handleSubmit} onChange={handleChange} />
                        </div>
                        <div className=" input-group">
                          <span class="input-group-text">IP</span>
                          <input type="number" className="form-control" name="impresora"
                            value={values.impresora} onSubmit={handleSubmit} onChange={handleChange} />
                        </div>
                        <div className=" input-group">
                          <span class="input-group-text">Puerto</span>
                          <input type="number" className="form-control" name="puerto"
                            value={values.puerto} onSubmit={handleSubmit} onChange={handleChange} />
                        </div></div>
                    </div>








                    <div className="text-center ">
                      {isLoading ? <button className="btn btn-primary   rounded-pill mr-2" ><div className="loading d-block isLoading"></div></button> : <button type="submit" className="btn btn-primary rounded-pill mr-2">
                        Actualizar
                      </button>
                      }
                      <Link to="/Admin/productos">
                        <button className="btn btn-primary rounded-pill">
                          Volver
                        </button></Link>
                    </div>

                  </form>
                }

              </div>
            </div>

          </div>

        </div>
      </section>

    </main>

  )

}
