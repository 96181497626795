import React, { useContext, useEffect, useState } from 'react';
import productList from '../../data/productList';
import Productos from "../../view/products";
import AppContext from '../../context/AppContext';
import ProductoItem from "../../view/productItem";
import { useCookies } from 'react-cookie';
import ProductoItemAdmin from '../../view/productItemAdmin';
import ProductosAdmin from '../../view/productsAdmin';



export default function MenuAdmin() {
  const { api, cart, deleteCartById, restToCart, link, setClick } = useContext(AppContext);
  const [cant, setcant] = useState(cart.card.length);

  const [currentPage, setCurrentPage] = useState(0);
  const [usuario] = useCookies(['usuarioadmi']);
  const [searchValue, setSearchValue] = useState('');

  const [totalPages, setTotalPages] = useState(1);
  const [data, setData] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [elementosFiltrados, setElementosFiltrados] = useState([]);
  let category = " "
  const fetchData = async () => {
    const result = await getData();

    setData(result);
    setIsLoading(true)
  };

  useEffect(() => {
    const productosFiltrados = data.filter((product) =>
      product.nombre.toLowerCase().includes(searchValue)
    );

    setElementosFiltrados(productosFiltrados)
  }, [searchValue]);

  const handleSearchChange = (e) => {
    setSearchValue(e.target.value.toLowerCase()); // Actualiza el estado con el valor del input en minúsculas
  };

  const getData = async () => {
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ idusuario: usuario.usuarioadmi.id })
      };

      const response = await fetch(`${api}/api/productos/`, requestOptions);
      const result = await response.json();

      async function fetchCategorias() {


        const response = await fetch(`${api}/api/categorias/`, requestOptions);

        const data = await response.json();
        setCategorias(data.map((categoria => ({ nombre: categoria.nombre, id: categoria.id }))));

      }


      fetchCategorias();
      const dataWithImageUrls = await Promise.all(
        result.map(async (item) => {

          return item;
        })
      );
      return dataWithImageUrls;


    } catch (error) {
      window.location.replace('/Admin/error')
    }
  };

  useEffect(() => {
    fetchData();
    window.scrollTo(0, 0);
  }, []);



  const onChangeLinkMenu = () => {
    setClick();
  }

  function addToCant(cant) {
    return setcant;
  }



  const sumaTotal = () => {
    const reducer = (accumalator, currentValue) => {
      const extraCost = currentValue.extra?.reduce(
        (extraAcc, extraItem) => extraAcc + extraItem.costo,
        0
      ) || 0;

      return accumalator + (currentValue.price + extraCost) * currentValue.qty;
    };
    const sum = cart.card.reduce(reducer, 0)
    return sum;
  }

  const onChangeActive = event => {
    document.querySelectorAll('.activeNav').forEach(function (item) { item.classList.remove('activeNav'); });
    // 👇️ toggle className on click
    event.currentTarget.classList.add('activeNav');

  }
  const [categoria, setCategoria] = useState("todos");

  useEffect(() => {
    const productosFiltrados = data.filter((product) =>
      product.nombre.toLowerCase().includes(searchValue)
    );

    setElementosFiltrados(productosFiltrados)
  }, [searchValue]);


  useEffect(() => {
    const handleActiveClass = () => {
      // Elimina la clase 'filter-active' de todos los elementos
      document.querySelectorAll('.filter-active').forEach(item => {
        item.classList.remove('filter-active');
      });

      // Obtiene el elemento 'li' correspondiente a la categoría seleccionada
      const activeElement = document.querySelector(`li[data-id="${categoria}"]`);

      // Asegura que el elemento existe antes de acceder a 'classList'
      if (activeElement) {
        activeElement.classList.add('filter-active');
      }
    };


    handleActiveClass();

    // Filtra los elementos según la categoría seleccionada
    const filteredElements = data.filter(
      elemento => categoria === "todos" || elemento.idcategoria.id === categoria
    );

    filteredElements.sort((a, b) => a.idcategoria - b.idcategoria);

    setElementosFiltrados(filteredElements);
  }, [categoria, data]);

  return (

    <section id="menu" className="pt-4 text-dark sectionMenu menu section-bg bg-white text-dark">

      <div className="container" data-aos="fade-up">

        <div className="section-title section-titleAdmi">
          <h2 className='fs-5'> Mi Menú</h2>
        </div>

        {isLoading ?
          <>

            <div className="row" data-aos="fade-up" data-aos-delay="100">
              <div className="col-lg-12 d-flex justify-content-center">

                <ul id="menu-flters">

                  {categorias.map(props =>
                    <li key={props.id} className={props.id === categoria ? "actived fw-bold" : " cate"} onClick={() => setCategoria(props.id)}>{props.nombre}</li>)}
                  <li className={categoria === "todos" ? "actived fw-bold " : "cate"} onClick={() => setCategoria("todos")}>Todos</li>
                </ul>
              </div>

            </div>

            <div className="box position-absolute  text-white mt-2 mb-4 pb-4">
              <form name="search" onSubmit={(e) => e.preventDefault()}>
                <input type="text" placeholder="Buscar" className="input bg-dark text-white" name="txt"
                  value={searchValue}
                  onChange={handleSearchChange} />
                <i className="bi bi-search"></i>
              </form>
            </div>

            <div id="pfilter" className="row menu-container" data-aos="fade-up" data-aos-delay="200">
              {elementosFiltrados.map((product => {
                categorias.map(categ =>
                  categ.id === product.idcategoria ? category = categ.nombre : " ")
                return <ProductosAdmin require key={product.id} img={product.img} categoria={category} nombre={product.nombre} price={product.precio} ingredients={product.ingredientes} id={product.id}></ProductosAdmin>;


              })
              )}

            </div>
          </> :

          <div className="center">  <div className=" spinner " style={{ backgroundColor: "var(--yellow)" }}></div></div>}
      </div>
      {cart.card.length > 0 ?

        <div id='inf' className=' rounded' style={{ maxWidth: "76vw" }}>
          <button className='total' onClick={onChangeLinkMenu}>   <p className="subtotal pedido">Ver Pedido ({cart.card.length})</p><strong />
            <p className="subtotal cartTotal pedido fs-5">$ {sumaTotal().toFixed(2)}</p></button>
        </div> : null}



      <div id="mobile">


      </div>
      <div >

        <ul id="carrito" className={link ? "active  bg-dark" : ""} style={{ maxWidth: "90vw" }}>
          <li className="cabezal text-white">
            <span id="closeCar" onClick={onChangeLinkMenu} ><span translate="no" className="material-icons-outlined">
              close
            </span></span> <span id="title">
              <h4>Resumen Pedido</h4>
            </span> <span ></span>
          </li>

          <ul id="pedido">
            <ProductoItemAdmin></ProductoItemAdmin>
          </ul>







        </ul>
        {/* <div className={link ? "opacar visible" : "opacar "}></div> */}

      </div>
      {/*  <div id="preloader"></div>*/}
    </section >


  )
}