import React, { useContext } from "react";
import { Link } from "react-router-dom";
import AppContext from "../context/AppContext";

export default function Nav() {
  const {active, setActive, toggleActive } = useContext(AppContext);

  const toggle = (props) =>{
    toggleActive(props)
    console.log(active)
  }
  
    return(
    <div className="d-flex align-items-center justify-content-between">
     <i onClick={()=> toggle('active')} className="bi bi-text-left barraAdmin"></i>

    <Link to="/Admin"  className="logo d-flex align-items-center">
    
    <img src="https://app.clickmenu.com.ar:8083/img/cmlogogreen.png" /> 
     <span className="d-none d-lg-block">Aliados</span>
   </Link>
  
 </div>)
}