import React, { useState, useRef } from 'react';
import { QRCodeCanvas } from 'qrcode.react'; 

export default function QR() {
  const [inputValue, setInputValue] = useState('https://app.clickmenu.com.ar');
  const [name, setName] = useState('');
  const qrRef = useRef();

  const handleInputChange = (e) => {
    //setInputValue(e.target.value);
  };

  const handleNameChange = (e) => {
    setName( e.target.value);
  };

  const downloadQR = () => {
    const fileName = name ? `QR.${name}.png` : 'qrcode.png'; 
    const canvas = qrRef.current.querySelector('canvas');
    const url = canvas.toDataURL('image/png');
    const link = document.createElement('a');
    link.href = url ;
    link.download = fileName;
    link.click();
  };

  const qrValue = name ? `${inputValue}/${name}` : inputValue;

  return (
    <main id="main" className={ 'main'}>
    <section className="section">
  <div className="row">
  <div className="card">
  <div className="card-body center">
      <h3>Generador de Código QR</h3>
      <input
        type="text"
        value={name}
        className="form-control center"
        onChange={handleNameChange}
        placeholder="Introduce el nombre de Local"
        style={{ padding: '10px', fontSize: '16px', width: '300px', marginBottom: '10px' }}
      />
      <input
        type="text"
        value={qrValue}
        onChange={handleInputChange}
        placeholder="Introduce la URL"
         className="form-control center"
        style={{ padding: '10px', fontSize: '16px', width: '300px' }}
      />
      <div
        ref={qrRef}
        style={{
          marginTop: '20px',
          padding: '20px',
          borderRadius: '20px', // Bordes redondeados
          border: '5px solid #000', // Bordes de 5px con color negro
          display: 'inline-block',
        }}
      >
        <QRCodeCanvas value={qrValue} size={256} />
      </div>
      <button className='btn btn-primary rounded-pill' onClick={downloadQR} style={{ marginTop: '20px', padding: '10px', fontSize: '16px' }}>
        Descargar Código QR
      </button>
      </div>
      </div>
    </div>
    </section>
    </main>
  );
}