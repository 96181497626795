
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { useParams } from 'react-router-dom';
import { useCookies } from "react-cookie";


export default function Img() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const { active, api } = useContext(AppContext);

  
  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append('imgs', event.target.img.files[0]);

    const getData = async () => {


      try {
        const response = await axios.post(`${api}/api/admin/upimg`, formData);
        console.log(response)
        if (response.status === 200 || response.status === 201) {
          setRegistroExitoso(true);
          event.target.reset();
        }
      } catch (error) {
        console.log(error);
      }
    }


    await getData();

  };
  



  return (
    <main id="main" className={active === 'active' ? 'main active' : 'main'}>

      <div className="pagetitle">
        {/* <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
            <li className="breadcrumb-item">Forms</li>
            <li className="breadcrumb-item active">Elements</li>
          </ol>
        </nav> */}
      </div>
      {registroExitoso && (
        <div className="alert alert-success" role="alert">
          ¡Actualización exitosa!
        </div>
      )}
      <section className="section">
        <div className="row">
          <div className="col-lg-6">

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Img</h5>


                <form  onSubmit={handleSubmit}>
             
                  <div className="row mb-3">
                    <label for="formFile" className="col-sm-2 col-form-label">Imagén</label>
                    <div className="col-sm-10">
                      <input className="form-control" type="file" id="formFile" name="img" accept="image/*" required />
                    </div>
                  </div>                
                 
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary rounded-pill">
                      Actualizar
                    </button>
                  </div>

                </form>

              </div>
            </div>

          </div>

        </div>
      </section>

    </main>

  )

}
