import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";


import { Link } from "react-router-dom";
import { useCookies } from "react-cookie";

export default function Tables() {
  const { active, api } = useContext(AppContext);
  const [mesas, setMesas] = useState([]);
  const [mesaSeleccionada, setMesaSeleccionada] = useState([]);
  const [pedidos, setPedidos] = useState([]);
  const [pedid, setPedid] = useState([]);
  const [pproductos, setPproductos] = useState([]);
  const [idpedido, setIdpedido] = useState([]);
  const [usuario] = useCookies(['usuarioadmi']);
  const isLocalUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
  const userId = usuario.usuarioadmi.id;

  useEffect(() => {

    
    const fetchMesas = async () => {
      const response = await fetch(`${api}/api/mesas/${usuario.usuarioadmi.id}`);
      if (response.ok) {
      const data = await response.json();
      setMesas(data);
      }
    };
    fetchMesas();
  }, []);
  let apiUrl = '';
  let endpoint = '';

if (isLocalUrl) {
  apiUrl = 'localhost:8093'; 
  endpoint = `ws:${apiUrl}/myHandler?userid=${userId}`;
} else {
  apiUrl = 'apiapp.clickmenu.com.ar:8093';
  endpoint = `wss:${apiUrl}/myHandler?userid=${userId}`;
}

  const destination = '/topic/app';
  let jsonpedidos = [];
  let pedi = [];

  useEffect(() => {
    const socket = new WebSocket(endpoint); // establece una conexión WebSocket
    socket.addEventListener('open', () => {
      console.log('WebSocket connection established');
    });
    try {
      socket.addEventListener('message', (event) => {

        const pedido = JSON.parse(event.data);
        if (pedido.pedidos) {
          jsonpedidos = pedido.pedidos;
          setPedidos(jsonpedidos)

          const productosArray = pedido.pproductos.map((producto) => {
            return {
              idpedido: producto.pedidoProductoPK.idpedido,
              idproducto: producto.pedidoProductoPK.idproducto,
              nombre: producto.producto,
              cantidad: producto.cantidad,
              precio: producto.precio,
              estado: producto.estado,
              idusuario: producto.idusuario,
              idmesa: producto.idmesa,
              observacion: producto.observacion
            };
          });
          //jsonproducto = JSON.stringify(pedido.pproductos);
         setPproductos(productosArray);


         // console.log(jsonproducto)
          pedi = pedido.pedidos
          setPedid(Object.values(pedi));
        

        } else {
          jsonpedidos = pedido; setPedidos(jsonpedidos)
      //    console.log(pedidos);
        }
       
      });
    } catch (error) {
      console.error('Error al analizar el JSON:', error);
    }

  }, []);

   

  useEffect(() => {
    mesas.map((mesa) => {
      let sumas = 0;
      let pagado = 0;
      let idmesa = 0;
      let pedidoId = [];
      const mesasSeleccionadas = {};
      const pps = [];

           
      setIdpedido(Object.values(pps));

      pedidos.forEach((pedido) => {
        const mesaId = pedido.idmesa;
        pedidoId = pedido.id;
        if (!mesasSeleccionadas[mesaId]) {
          mesasSeleccionadas[mesaId] = {
            sumas: 0,
            pagado: 0,
            idmesa: mesaId,
            idpedido: [],
          };
        }

       

        mesasSeleccionadas[mesaId].sumas += parseFloat(pedido.consumototal);
        mesasSeleccionadas[mesaId].pagado += pedido.pagado !== null ? parseFloat(pedido.pagado) : 0;
        mesasSeleccionadas[mesaId].idpedido.push(pedidoId);
      });

      setMesaSeleccionada(Object.values(mesasSeleccionadas));
// console.log('mesa' +mesasSeleccionadas);
    })

  }, [mesas, pedidos]);


  useEffect(() => {
  //  console.log('Sumas:', mesaSeleccionada);
  //  console.log( 'pp'+ pproductos)
  }, [mesaSeleccionada, pproductos]);



  const elementosFiltrados = mesas.sort((a, b) => {
    if (a.Mesa === 1 && b.Mesa !== 1) {
      return -1;
    } else if (a.Mesa !== 1 && b.Mesa === 1) {
      return 1;
    } else {
      return 0;
    }
  });

  const total = elementosFiltrados.Productos ? elementosFiltrados.Productos.reduce((acc, product) => acc + (product.price * product.cantidad), 0) : 0;


  return (

    <main id="main" className={active === 'active' ? 'main active' : 'main'}>

      <div className="pagetitle">
        <h1>Gestión de Mesas</h1>
       {/* <nav>
          <ol className="breadcrumb">
            <li className="breadcrumb-item"><a href="index.html">Home</a></li>
            <li className="breadcrumb-item active">Dashboard</li>
          </ol>
  </nav>*/}
        <div  className="endFlex">
    <Link to="Admin/mesaalta"
              className="btn btn-primary rounded-pill"> <i className="bi bi-plus-circle-dotted"></i> Alta Mesa</Link></div>
      </div>

      <section className="section dashboard categorias breadcrumb" id="categorias">
        {elementosFiltrados.map((table, id) => (

          <div className={table.Consumo - table.Pagado !== 0 ? "categoriaAdmi" : "categoriaAdmi pagado"} data-bs-toggle="modal" data-bs-target={`#exampleModal-${id}`}>
            <div className="num"> <h5>#{table} |</h5> <h6> {table.capacidad}<i className="bi bi-x-diamond"></i> </h6></div>

            {mesaSeleccionada.find(mesa => mesa.idmesa === table.id) ? (
              mesaSeleccionada.find(mesa => mesa.idmesa === table.id).sumas
                - mesaSeleccionada.find(mesa => mesa.idmesa === table.id).pagado !== 0 ? ( <>
                <h5 id="resta">${mesaSeleccionada.find(mesa => mesa.idmesa === table.id).sumas.toLocaleString('es-AR')}</h5>
                <h6 className="timer"><i className="bi bi-clock-history"></i> 22min</h6> </>
              ) : (
                <h5>Pagado</h5>
              )) : <><h6 className="free"> Libre </h6> 
            <i className="bi bi-check-circle free"></i>
              </>
            } 


            {/* <p>{table.timer}</p> */}

            <div className="modal fade" id={`exampleModal-${id}`} tabIndex="-1" aria-labelledby={`exampleModalLabel-${id}`} aria-hidden="true">
              <div className="modal-dialog">
                <div className="">
                  <div className="">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Detalle Factura - Mesa  {table}</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  {mesaSeleccionada.find(mesa => mesa.idmesa === table.id) ? (

                  <div className="">


                  {pproductos.filter((pp) => pp.idmesa === table.id).map((product, id) => (
                    <ul className="factu" key={id}>
                      <li>{product.cantidad} un.</li>
                      <li>{product.nombre}</li>
                      <li>${product.precio * product.cantidad}</li>
                    </ul>
                  ))}
                    <h5>Consumo Total: ${ mesaSeleccionada.find(mesa => mesa.idmesa === table.id).sumas.toLocaleString('es-AR',)}</h5>
                    <h5 className="pago">Pago Online: ${mesaSeleccionada.find(mesa => mesa.idmesa === table.id).pagado.toLocaleString('es-AR',)}</h5>
                    <h6>Subtotal: ${(mesaSeleccionada.find(mesa => mesa.idmesa === table.id).sumas
                - mesaSeleccionada.find(mesa => mesa.idmesa === table.id).pagado).toLocaleString('es-AR',)}</h6> 
                  </div>

                    ) : <h5> - </h5>





                    }



                  <div className="">
                    {/*table.Consumo - table.Pagado === 0 ? <button type="button" className="btn btn-primary">Liberar</button> : ''*/}
                    <button type="button" className="btn btn-primary">Imprimir</button>
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>

                  </div>
                </div>
              </div>
            </div>
          </div>

        )


        )}



      </section>
    </main>
  )


}

