
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import AppContext from "../../context/AppContext";
import { notification } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Adicionales from "../../components/adicionales";
import ProductoInsumo from "../../components/productoinsumo";

export default function InsumoAlta() {


  const { active, api } = useContext(AppContext);
  const [categorias, setCategorias] = useState([]);
  const [categoriaId, setCategoriaId] = useState("");
  const [usuario] = useCookies(['usuarioadmi']);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sintacc, setSintacc] = useState(false);
  const [opcional, setOpcional] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [unidadSeleccionada, setUnidadSeleccionada] = useState("");

  const handleUnidadClick = (value) => {
    setUnidadSeleccionada(value);
  };
  useEffect(() => {
    async function fetchProvedores() {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ usuario: usuario.usuarioadmi.id })
      };

      const response = await fetch(`${api}/api/insumo/provedores`, requestOptions);
      const data = await response.json();
      setCategorias(data);
    }
   // fetchProvedores();

  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true)
    if (opcional && selectedItems.length === 0) {
      notification.open({
        message: "Notificacion: Extra o Requerido",
        description: "Debe seleccionar entre la lista de ingrediente.",
        icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
      });
      return;
    }

    const formData = new FormData();
    formData.append('nombre', event.target.nombre.value);
    formData.append('preciounitario', event.target.precio.value);
    formData.append('cantidad', event.target.stock.value);
    formData.append('vencimiento', event.target.fin.value);
    formData.append('usuario', usuario.usuarioadmi.id);
    formData.append('proveedor', categoriaId);
    //formData.append('activo', event.target.activo.checked);
    formData.append('unidad', event.target.unidad.value);



    const getData = async () => {


      try {
        const response = await axios.post(`${api}/api/productos/alta`, formData);
        console.log(response)
        if (response.status === 200 || response.status === 201) {
          notification.open({
            message: 'Notificación',
            description: 'Registro exitoso',
            icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
          });
          event.target.reset();
        }
        setOpcional(false)
        setIsLoading(false)

      } catch (error) {
        setIsLoading(false)

        notification.open({
          message: "Ocurrio un error 😢",
          description: `${error.response?.data || error.message}`,
          icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
        });
      }
    }


    await getData();

  };



  /*------------------------Listado Ingrediente-------------------------*/




  // Función que actualiza el estado en el padre
  const handleSelectedItemsUpdate = (newSelectedItems) => {
    setSelectedItems(newSelectedItems);
  }
  return (
    <main id="main" className={active === 'active' ? 'main active' : 'main'}>

      {/* <div className="pagetitle">
        <nav>
          <ol className="breadcrumb"> <h1>Altas Productos</h1>
        </ol>
        </nav>
      </div> 

      <span className="rigth"><i className="bi bi-arrow-return-left"></i></span>*/}
      <section className="section">
        <div className="row">
          <div className="col-lg-8">

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Alta Insumo</h5>


                <form onSubmit={handleSubmit}>
                  {/* <div className="row mb-3">
                    <label for="inputText" className="col-sm-2 col-form-label">Activo</label>
                    <div className="col-sm-5 form-check form-switch">
                      <input className="form-check-input" type="checkbox" name="activo" id="gridCheck2" value="true" checked="true" />
                    </div>
                  </div> */}
                  <div className="row mb-3">
                    <label for="inputText" className="col-sm-2 col-form-label" >Nombre</label>
                    <div className="col-sm-5">
                      <input type="text" className="form-control" name="nombre" required />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputPassword" className="col-sm-2 col-form-label">Precio Unitario</label>
                    <div className="col-sm-5">
                      <input type="number" className="form-control" name="precio" required />
                    </div>
                  </div>


                  
                  <div className="row mb-3">
                    <label className="col-sm-7 col-form-label">Unidad de Medida</label>
                    <div className="col-sm-7 d-flex flex-wrap gap-1 mt-1">
                      {[
                        { label: "Kilogramos", value: "kg" },
                        { label: "Gramos", value: "g" },
                        { label: "Litros", value: "l" },
                        { label: "Unidad", value: "unidad" },
                        { label: "Docena", value: "docena" },
                       /* { label: "Mililitros", value: "ml" },  { label: "Metros (m)", value: "m" },
                        { label: "Centímetros (cm)", value: "cm" }, */
                      ].map((unidad) => (
                        <button
                          key={unidad.value}
                          type="button"
                          className={`btn ${unidadSeleccionada === unidad.value
                              ? "btn-primary"
                              : "btn-outline-dark"
                            }`}
                          onClick={() => handleUnidadClick(unidad.value)}
                        >
                          {unidad.label}
                        </button>
                      ))}
                    </div>
                  </div>

                  
                  <div className="row mb-3">
                    <label for="inputNumber" className="col-sm-2 col-form-label">Cantidad</label>
                    <div className="col-sm-5">
                      <input type="number" className="form-control" name="stock" />
                    </div>
                    {/* <input type="hidden" name="idUsuario" value="17" /> */}
                  </div>
                  <div className="row mb-3">
                    <label for="inputPassword" className="col-sm-2 col-form-label">Vencimiento</label>
                    <div className="col-sm-5">
                      <input type="date" className="form-control" name="fin" required />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputDate" className="col-sm-2 col-form-label">Proveedor</label>
                    <div className="col-sm-5">
                      <select className="form-select" aria-label="Default select example" name="idcategoria" onChange={(e) => setCategoriaId(e.target.value)} required >
                        <option value="" className="gris">Elegir un proveedor</option>
                        {categorias.map((categoria) => (
                          <option key={categoria.id} value={categoria.id}>{categoria.empresa + '| '+ categoria.nombre}</option>
                        ))}
                      </select>
                    </div>
                  </div>


                  
                    <ProductoInsumo unidadSeleccionada={unidadSeleccionada} ></ProductoInsumo>
                
                    <div className="text-center mt-3">
                      <button type="submit" className="btn btn-primary rounded-pill">
                        Registrar
                      </button>
                    </div>
                
                </form>

              </div>
            </div>

          </div>

        </div>
      </section>

    </main>

  )

}

