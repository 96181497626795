import { Component } from "react";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    // Actualiza el estado para mostrar una interfaz alternativa.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // Puedes registrar el error en un servicio externo.
    this.setState({ error, errorInfo });
    console.error("Error capturado por ErrorBoundary:", error, errorInfo);
  }

  handleReset = () => {
    // Restablece el estado para reintentar.
    this.setState({ hasError: false, error: null, errorInfo: null });
  };

  render() {
    if (this.state.hasError) {
      return (
        <div role="alert" style={{ padding: "20px", textAlign: "center", marginTop: "150px" }}>
          <h1>Oops! Algo salió mal.</h1>
          <pre style={{ color: "red", padding: "40px", marginLeft: "150px"  }}>
            {this.state.error?.toString()}
          </pre>
          <button
            onClick={this.handleReset}
            style={{
              padding: "10px 20px",
              border: "none",
           
             
              borderRadius: "5px",
              cursor: "pointer",
            }}
          >
            Intentar nuevamente
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;