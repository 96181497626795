import { useEffect, useState } from "react"


const initialState = {
    card: [],

}
const useInitialState = (props) => {
    const [pedido, setPedido] = useState(initialState);
    const [cart, setCart] = useState(initialState);
    const [link, setLink] = useState(false);
    const [modo, setModo] = useState("LOCAL");
    const [table, setTable] = useState(0);
    const [cliente, setCliente] = useState([]);
    const [total, setTotal] = useState([]);
    const [mozo, setMozo] = useState([]);
    const [pago, setPago] = useState([]);
    const [estado, setEstado] = useState('');
    const [titulo, setTitulo] = useState('');
    const isLocalUrl = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
    let api = " ";
    if (isLocalUrl) {
        api =  `http://localhost:8093`; 
     
      } else {
        api = `https://app.clickmenu.com.ar:8093`
      }   
   const [active, setActive] = useState(null);
   const [isLoggedIn, setIsLoggedIn] = useState(false);

   const handleLoginSuccess = (result) => {
        setIsLoggedIn(result)
    }


   const toggleActive = () => {
    setActive(active === 'active' ? null : 'active' )
    console.log(props)
  }

    const addToCart = (item, qty, adicionales, extra) => {
       
       
        if (cart.card.some(el => el.id === item.id)) {
            console.log("ya hay un producto igual", cart.card.length)

            let index = cart.card.findIndex(el => el.id === item.id);
            let product = cart.card[index]
               
            product.qty = product.qty + 1;
            
            product.extra = extra ;

            product.adicionales = adicionales;

            const newCart = [...cart.card];
            newCart.splice(index, 1, product);
        
            setCart({
                card: [...newCart]});
        
        } else {
            let producto = { ...item, qty, adicionales, extra };
            setCart({...cart,
                card: [ ...cart.card, producto]})
        }
    
    }

    const restToCart = (item, qty) => {
       
       
        if (cart.card.some(el => el.id === item.id)) {


            let index = cart.card.findIndex(el => el.id === item.id);
            let product = cart.card[index]
               
            product.qty = product.qty - 1;

            const newCart = [...cart.card];
            newCart.splice(index, 1, product);
        
            setCart({
                card: [...newCart]});

        }
    }

    const deleteCartById = (id) => {

        setCart(prevCart => {
            const newCart = [...prevCart.card];
            const index = newCart.findIndex(item => item.id === id.id);
            if (index !== -1) {
              newCart.splice(index, 1);
            }
            return { card: newCart };
          });
      
        // let index = cart.card.findIndex(el => el.id === id);
        // const newCart = [...cart.card];
        // newCart.splice(index, 1);
        
        // setCart({
        //     card:[...newCart]});
    }

    const deleteCart = () => {
        setCart(    { card: []} );
    }

  

    // const updateCliente = (user) => {
    //   const updatedCliente = Object.values({
    //     nombre: user.nombre,
    //     telefono: user.telefono.toString(),
    //     email: user.email
    //   });
    //   setCliente(updatedCliente);
    // };


    const pedidos = (item) => {
       
    
            const productos = { ...item };
            setPedido({...pedido,
                items: [ ...pedido.card, productos]})
        
    
    }

    const setClick = () => {
        setLink(!link)
    }



return {
    cart,
    setCart,
    addToCart,
    deleteCartById,
    deleteCart,
    cart,
    restToCart,
    cliente,
    setCliente,
    pedidos,
    pedido,
    total,
    table,
    mozo,
    pago,
    estado,
    titulo,

    active,
    setActive, 
    toggleActive,
    handleLoginSuccess,
    isLoggedIn,
    api,
    link,
    setClick,
    modo,
    setModo,
    setTotal,
    setTable,
    setMozo,
    setPago,
    setEstado,
    setTitulo,
    

}
    
}

export default useInitialState;