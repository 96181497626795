
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../context/AppContext";
import { useNavigate, useParams } from 'react-router-dom';
import Password from "antd/es/input/Password";
import { notification } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";


export default function EditarMozo() {
  const navigate = useNavigate();

  const { id } = useParams();
  const [registroExitoso, setRegistroExitoso] = useState(false);
  const { active, api } = useContext(AppContext);
  const [values, setValues] = useState({
    nombre: '',
    apellido: '',
    password: '',
    activo: '',
    telefono: '',
    email: '',
  });
  useEffect(() => {
    axios
        .post(`${api}/api/mesero/mozo`, { id }) // Pasar 'id' en el cuerpo
        .then((response) => {
          setValues(response.data);
        })
        .catch((error) => {
          console.error("Error fetching mesero:", error);
        });
  }, [id]);

  const handleSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`${api}/api/mesero/editar`, values)
      .then((response) => {
     debugger
        setRegistroExitoso(true);

        
        notification.open({
          message: 'Notificación',
          description: 'Actualización exitosa',
          icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
        });
        navigate(-1);
      })
      .catch((error) => {

        notification.open({
          message: "Ocurrio un error 😢",
          description: `${error.response?.data || error.message}`,
          icon: <CloseCircleOutlined style={{ color: "#bd0003" }} />,
        });
        console.log(error);
      });
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  return (
    <main id="main" className={active === 'active' ? 'main active' : 'main'}>

      <div className="pagetitle">
        <h1>Editar Cliente</h1>
      
      </div>
      {registroExitoso && (
        <div className="alert alert-success" role="alert">
          ¡Registro exitoso!
        </div>
      )}
      <section className="section">
        <div className="row">
          <div className="col-lg-6">

            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Editar Mesero</h5>


                <form onSubmit={handleSubmit}>
                <div className="row mb-3">
                      <label for="inputText" className="col-sm-2 col-form-label"> Activo</label>
                      <div className="col-sm-10 form-check form-switch d-flex justify-content-start">
                        <input className="form-check-input" type="checkbox" name="activo" id="gridCheck2"
                          checked={values.activo} onSubmit={handleSubmit}
                          onChange={handleChange} />
                      </div>
                    </div>
                  <div className="row mb-3">
                    <label for="inputText" className="col-sm-2 col-form-label">Nombre</label>
                    <div className="col-sm-10">
                      <input type="text" className="form-control" name="nombre" value={values.nombre}
                        onSubmit={handleSubmit} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputEmail" className="col-sm-2 col-form-label">Apellido</label>
                    <div className="col-sm-10">
                      <input type="text" className="form-control" name="apellido" value={values.apellido}
                        onSubmit={handleSubmit} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputPassword" className="col-sm-2 col-form-label">Email</label>
                    <div className="col-sm-10">
                      <input type="text" className="form-control" name="email" value={values.email} autoComplete="off"
                        onSubmit={handleSubmit} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputNumber" className="col-sm-2 col-form-label">Contraseña</label>
                    <div className="col-sm-10">
                      <input type="password" className="form-control" name="local"  autocomplete="new-password"   pattern="^\d{4}$"
                         maxLength="4"
                        title="La contraseña debe tener 4 caracteres númerico."
                        onSubmit={handleSubmit} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <label for="inputPassword" className="col-sm-2 col-form-label">Celular</label>
                    <div className="col-sm-10">
                      <input type="number" className="form-control" name="telefono" value={values.telefono}
                        onSubmit={handleSubmit} onChange={handleChange} />
                    </div>
                  </div>
                  
                
                  <div className="text-center">
                    <button type="submit" className="btn btn-primary rounded-pill">
                      Actualizar
                    </button>
                  </div>

                </form>

              </div>
            </div>

          </div>

        </div>
      </section>

    </main>

  )

}
