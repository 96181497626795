import { Link } from "react-router-dom";


export default function ErrorMenu() {







    return (

        <section className=" ">
            <div className="moon"></div>
            <div className="moon__crater moon__crater1"></div>
            <div className="moon__crater moon__crater2"></div>
            <div className="moon__crater moon__crater3"></div>

            <div className="star star1"></div>
            <div className="star star2"></div>
            <div className="star star3"></div>
            <div className="star star4"></div>
            <div className="star star5"></div>

            <div className="errorMenu">  <Link to={'/ClickMenu'} className="error__button error__button--active">INICIO</Link>
                <button className="error__button">CONTACTO</button>
                <div className="error__title">Uy...</div>
              
                <div className="error__subtitle">...algo salio mal</div>
                <div className="error__description">Error: Contactanos y lo resolveremos</div>
                
            </div>

            <div className="astronaut">
                <div className="astronaut__backpack"></div>
                <div className="astronaut__body"></div>
                <div className="astronaut__body__chest"></div>
                <div className="astronaut__arm-left1"></div>
                <div className="astronaut__arm-left2"></div>
                <div className="astronaut__arm-right1"></div>
                <div className="astronaut__arm-right2"></div>
                <div className="astronaut__arm-thumb-left"></div>
                <div className="astronaut__arm-thumb-right"></div>
                <div className="astronaut__leg-left"></div>
                <div className="astronaut__leg-right"></div>
                <div className="astronaut__foot-left"></div>
                <div className="astronaut__foot-right"></div>
                <div className="astronaut__wrist-left"></div>
                <div className="astronaut__wrist-right"></div>


                <div className="astronaut__head">
                    <canvas id="visor" width="60px" height="60px"></canvas>
                    <div className="astronaut__head-visor-flare1"></div>
                    <div className="astronaut__head-visor-flare2"></div>
                </div>
                <div className="astronaut__cord">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200">
                        <path className="hose" d="M20,100 C80,50 120,150 180,100" fill="none" stroke-width="10" />
                        <circle className="connector" cx="12" cy="100" r="12" fill="#2f3640" />
                        <circle className="connector" cx="180" cy="100" r="12" fill="#e67e22" />
                    </svg>
                </div>

            </div></section>
    )
}