import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
//import { render } from "react-dom";
import React, { useEffect } from "react";
import useInitialState from "../hooks/useInitialState";
import "../vendor/swiper/swiper-bundle.min.css";
import AppContext from "../context/AppContext";
import MenuApp from "./MenuApp";
import AdminApp from "./AdminApp";
import AOS from "aos";

export default function App() {
  const initialState = useInitialState();
  const admin = localStorage.getItem('Admin')

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <AppContext.Provider value={initialState}>
      <BrowserRouter>

        {admin ?
          <AdminApp /> :
          <MenuApp className='bodyMenu'/>}

      </BrowserRouter >
       </AppContext.Provider >)
}
